export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage?: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  linkedin?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  /**
   * full url, no username
   */
  instagram?: string;
  /**
   * full url, no username
   */
  github?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const config: WebsiteConfig = {
  title: 'davidgonzalezfx | blog',
  description: 'Pray | Code | Trade | Grow',
  coverImage: 'img/davidgonzalezfx-blog.png',
  logo: 'img/davidgonzalezfx-logo.png',
  lang: 'en',
  siteUrl: 'https://www.davidgonzalezfx.com',
  facebook: 'https://www.facebook.com/davidgonzalezfx',
  linkedin: 'https://www.linkedin.com/in/davidgonzalezfx/',
  twitter: 'https://twitter.com/davidgonzalezfx',
  instagram: 'https://instagram.com/davidgonzalezfx',
  github: 'https://github.com/davidgonzalezfx',
  googleSiteVerification: 'GoogleCode',
  footer: 'is based on Gatsby',
};

export default config;
